import React, { useEffect, useState } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import '../css/app.css'
const IsolateContent = () => {
   
    return (
        <>
            <Outlet />
        </>
    );
};


export default IsolateContent;